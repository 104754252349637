<template>
    <div>
        <div class="d-flex justify-content-start align-items-center my-10">
            Toplam {{ totalItems }} müşteri bulundu.
        </div>
        <div class="d-flex align-items-center justify-content-between search-list-area">
            <div class="d-flex">
            <div class="d-flex align-items-center search-input">
                <input  placeholder="Ara.." v-model="search" class="form-control mr-2" />
               
            </div>
            <select class="form-control"  id="selectType" v-model="customerType" >
                    <option value="" selected="selected">Müşteri Tipi Seçiniz</option>
                    <option value="Bireysel">Bireysel</option>
                    <option value="Ticari">Ticari</option>
                    <option value="Özel">Özel</option>
                    <option value="Kiraci">Kiracı</option>


                </select>
            </div>
            
            <div class="d-flex align-items-center search-buttons ">
               
                
               <div class="d-flex align-items-center">
                <b-form-group>
                    <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <span>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0 2.40898V9.92754C0 10.7825 0.336953 11.6022 0.94045 12.2057L9.79175 21.057C11.049 22.3143 13.0858 22.3143 14.3431 21.057L21.057 14.3431C22.3143 13.0858 22.3143 11.049 21.057 9.79176L12.2057 0.940467C11.6022 0.33697 10.7825 1.74727e-05 9.92753 1.74727e-05H2.41399C1.08127 -0.00501167 0 1.07625 0 2.40898ZM5.63264 4.0183C6.05946 4.0183 6.4688 4.18786 6.77061 4.48966C7.07242 4.79147 7.24197 5.20081 7.24197 5.62763C7.24197 6.05445 7.07242 6.46379 6.77061 6.76559C6.4688 7.0674 6.05946 7.23695 5.63264 7.23695C5.20582 7.23695 4.79648 7.0674 4.49468 6.76559C4.19287 6.46379 4.02332 6.05445 4.02332 5.62763C4.02332 5.20081 4.19287 4.79147 4.49468 4.48966C4.79648 4.18786 5.20582 4.0183 5.63264 4.0183Z"
                                        fill="#102640" fill-opacity="0.5" />
                                </svg></span>
                        </template>
                        <b-dropdown-item href="#">En Yüksek Gelire Göre</b-dropdown-item>
                        <b-dropdown-item href="#">En Düşük Gelire Göre</b-dropdown-item>
                        <b-dropdown-item href="#">Artan Mülk Sayısına Göre</b-dropdown-item>
                        <b-dropdown-item href="#">Azalan Mülk Sayısına Göre</b-dropdown-item>
                        <b-dropdown-item href="#">Favoriler</b-dropdown-item>
                    </b-dropdown>
                </b-form-group>
                <b-form-group>
                    <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <span><i class="flaticon-star"></i></span>
                        </template>
                        <b-dropdown-item href="#">Action</b-dropdown-item>
                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                        <b-dropdown-item href="#">Something else here...</b-dropdown-item>
                    </b-dropdown>
                </b-form-group>
                <b-form-group>
                    <router-link to="/musteriler/musteri-ekle" v-slot="{ href, navigate, isActive, isExactActive }">

                        <b-button variant="primary" class="ml-auto" :href="href" @click="navigate">Müşteri Ekle</b-button>
                    </router-link>
                </b-form-group>
               </div>
                
            </div>
        </div>


        <div class="custom-list">
            <List :header="header" :data="items" :pagination="pagination" :loading="loading" @updatePage="updatePage"
                moduleName="Müşteriler Listesi" :moduleCreateEvent="true" :moduleSearch="search"
                @selectedItemIdsChanged="selectedItemIdsChanged" @openCreateModal="openInsertUpdate('')">
                <template v-slot:item.fullName="{ item }">
                    {{item.Adi }} {{  item.Soyadi }}
                </template>
                <template v-slot:item.MusteriTipi="{item}">
                    <b-badge variant="primary">{{item.MusteriTipi}}</b-badge>

                </template>
                <template v-slot:item.email="{ item }">
                    <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                </template>
                <template v-slot:item.isActive="{ item }">
                    <div v-html="$customFunctions.getIsActiveBadge(item.isActive)"></div>
                </template>
                <template v-slot:item.star="{ item }">
                    <div v-html="$customFunctions.getIsStar(item.star)"></div>
                </template>
                <template v-slot:item.IsActive="{ item }">
                    <b-badge variant="success" v-if="item.IsActive === true" >Aktif</b-badge>
                    <b-badge variant="danger" v-else>Pasif</b-badge>

                </template>
                <template v-slot:item.IsDeleted="{ item }">
                    <b-badge variant="success" v-if="item.IsDeleted === true" >Aktif</b-badge>
                    <b-badge variant="danger" v-else>Pasif</b-badge>

                </template>
                <template v-slot:item.DogumTarihi="{item}">
                    <p class="text-dark" v-html="formatDate(item.DogumTarihi)"></p>
                </template>
                
                <template v-slot:item.action="{ item }" class="p-0">
                    <b-dropdown size="sm" no-caret dropleft variant="muted" style="z-index: 999;">
                        <template v-slot:button-content>
                            <i class="ki ki-bold-more-ver text-dark"></i>
                        </template>
                        <b-dropdown-item @click="openDetail(item)">Detay</b-dropdown-item>
                        <b-dropdown-item @click="openInsertUpdate(item)">Düzenle</b-dropdown-item>
                        <b-dropdown-item v-if="item.IsDeleted === false " @click="openDeleteHandler(item)">Sil</b-dropdown-item>
                    </b-dropdown>
                </template>
                
            </List>
            <!-- Create and Actions Popups -->
            <InsertUpdateModal :title="selectedItem == ''
                ? 'Sistem Kullanıcı Kaydı Ekle'
                : 'Sistem Kullanıcını Düzenle'
                " :show="showInsertUpdate" @closeDetail="closeInsertUpdate()" @confirm="confirmModal()"
                :resetForm="resetForm">
                <InsertUpdateOperations :selectedItem="selectedItem" :confirmModal="confirmCreate"
                    @resetInsertUpdateForm="resetInsertUpdateForm" />
            </InsertUpdateModal>

            <DetailModal title="Müşteri Detayı" :show="showDetail"
                @openInsertUpdate="openInsertUpdate(selectedItem)" @closeDetail="closeDetail">
                <DetailView :selectedItem="selectedItem" />
            </DetailModal>

            <ImportModal :show="showImportModal" importApiEndpoint="user/import" @closeDetail="closeImportModal()"
                @updateList="getList" @downloadSampleFile="exportOperations(true)" />

            <DeleteOperation       :url="deleteOperationUrl"
      :params="deleteIds"
      @updateList="getList"
      modalid="deleteCustomer"
 />
        </div>
    </div>
</template>

<style lang="scss">
    @media (max-width:990px) {
        .search-list-area{
            flex-direction: column;
            .search-input{
                width: 100%;
                *{
                    width: 100%;
                }
            }
        }
        .search-buttons{
            flex-direction: column;
        }
    }

</style>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment'
moment.locale('tr')
export default {
    name: 'customerList',
    data() {
        return {
            search: '',
            filters: '',
            showInsertUpdate: false,
            showDetail: false,
            showImportModal: false,
            resetForm: false,
            confirmCreate: false,
            loading: false,
            header: [
                { text: '', value: 'id', checkboxField: true, size: '50px' },
                { text: 'Müşteri Tipi', value: 'MusteriTipi'},
                { text: 'Adı - Soyadı', value: 'fullName' },
                { text: 'T.C Kimlik Numarası', value: 'Tckn' },
                { text: 'E-Posta', value: 'Eposta' },
                { text: 'Doğum Tarihi', value: 'DogumTarihi' },
                { text: 'Telefon', value: 'Telefon' },
                { text: 'Durum', value: 'IsActive', size: '60px' },
                { text: 'Silme Durumu', value: 'IsDeleted', size: '60px' },

                { text: '', value: 'action', size: '60px' },
            ],
            items: [],
            pagination: {},
            meta: {},
            listSelectedItemIds: [],
            selectedItem: '',
            deleteOperationUrl: '',
            deleteIds: '',
            customerType:""
        };
    },

    created() {
        this.getList();
    },
    methods: {

        getList() {

            this.loading = true;

            this.items = [];
            this.totalItems = 0;
            this.loading = true;

            let queryLimit = this.list_length ? this.list_length : 10;
            let query = `musteriler_list?offset=${
            this.page ? this.page : 0
            }&limit=${queryLimit}&token=${this.myUser}`;
            if (this.search) {
                query += `&searchValue=${this.search}&column=MusteriTipi,Adi,Soyadi,Eposta,Telefon`;
            }
            if(this.customerType){
                query += `&searchValue=${this.customerType}&column=MusteriTipi`;
            }

            this.$ApiService
            .get(query)
            .then((res) => {
                if (res.status == 200) {
                    if(res.data.status===200){
                        this.items = res.data.result;
                        this.totalItems = res.data.result?.length;
                        this.pagination = {
                            current_page: this.page,
                            total_pages: Math.ceil(10 / 10),
                            total_items: this.totalItems,
                        };
                    }else{
                        this.items = []
                    }
                }else{
                    
                    this.items = []
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                this.loading = false;
            });


            
        },
        updatePage(value) {
            this.meta = value;

            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
        confirmModal() {
            this.confirmCreate = true;
            setTimeout(() => {
                this.confirmCreate = false;
            }, 200);
        },
        resetInsertUpdateForm(closeInsertUpdate = false) {
            if (closeInsertUpdate) {
                this.closeInsertUpdate();
                this.getList();
            }

            this.resetForm = true;
            setTimeout(() => {
                this.resetForm = false;
            }, 200);
        },
        formatDate(date){
            return moment(date).format('LL')
        },
        closeInsertUpdate() {
            setTimeout(() => {
                this.selectedItem = '';
            }, 100);
            this.showInsertUpdate = false;
        },
        openInsertUpdate(item) {
            this.selectedItem = item;
            this.showInsertUpdate = true;
        },
        closeDetail() {
            this.showDetail = false;
        },
        openDetail(item) {
            this.selectedItem = item;
            this.showDetail = true;
        },
        closeImportModal() {
            this.showImportModal = false;
        },
        openImportModal() {
            this.showImportModal = true;
        },
        openDeleteHandler(item) {
            this.deleteOperationUrl = `musteriler_delete`;
            this.deleteIds = {token:this.myUser,id:item._id.$oid};
            this.$bvModal.show('modal-deleteOperationdeleteCustomer');
        },
        selectedItemIdsChanged(payload) {
            this.listSelectedItemIds = payload;
        },
        actions(payload) {
            if (
                this.listSelectedItemIds === [] ||
                this.listSelectedItemIds.length == 0
            ) {
                this.$generateNotification(
                    this,
                    'warning',
                    'İşlem yapılacak seçili kayıt bulunamadı!',
                    'İşleme devam edilemiyor.'
                );
            } else {
                if (payload == 'allRemove') {
                    this.deleteOperationUrl = `user`;
                    this.deleteIds = this.listSelectedItemIds;
                    this.$bvModal.show('modal-deleteOperation');
                } else if (payload == 'allInActive' || payload == 'allActive') {
                    this.loading = true;
                    this.$dbFunctions
                        .update(`user/update-status`, {
                            ids: this.listSelectedItemIds,
                            isActive: payload == 'allActive',
                        })
                        .then((res) => {
                            this.$dbFunctions.setResponse(this, res);
                            this.getList();
                        })
                        .catch((err) => {
                            this.$dbFunctions.setResponse(this, err);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                } else if (payload == 'allExport') {
                    this.exportOperations(false);
                }
            }
        },
        exportOperations(onlyHeaders) {
            this.loading = true;
            let payload = {
                Ids: this.listSelectedItemIds,
                onlyHeaders: onlyHeaders,
            };

            this.$dbFunctions
                .insertOrUpdate(`user/export`, 'export', payload)
                .then((res) => {
                    // this.$customFunctions.downloadFile(res.data);

                    this.$generateNotification(
                        this,
                        'success',
                        `Size özel dosyanız oluşturuldu. <a href="${res.data}" target="_blank" class="text-dark-50 text-hover-dark">Burayı tıklayarak</a> dosyanızı indirebilirsiniz.`,
                        'Dosyanız hazır!'
                    );
                })
                .catch((err) => {
                    this.$dbFunctions.setResponse(this, err);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

    },
    watch: {
        meta: {
            handler() {
                this.getList();
            },
            deep: true,
        },
        search: function (params) {
                this.getList();
            
            
        },
        customerType:function(){
            this.getList();
        }
    },
    components: {
        InsertUpdateOperations: () =>
            import('@/components/systemUser/modals/InsertUpdateOperations'),
        DetailView: () => import('@/components/customers/modals/DetailModal'),
    },
    computed: {
    ...mapGetters(["myUser"]),
  },
};
</script>
  